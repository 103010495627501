<template>
  <v-container
    class="profile-update-container scroll-container"
    v-if="userData"
  >
    <CategoryTitle :category="category" class="mb-3" :addContainer="false" />
    <div v-show="!selfScanning">
      <p
        class="text-uppercase text-h4 font-weight-bold"
        v-html="$t('profile.update.personalData')"
      />

      <!-- PERSONAL DATA -->
      <UserData
        ref="personalDataForm"
        :userData.sync="userData"
        :supportData.sync="supportData"
        :askFirstName="true"
        :askLastName="true"
        :askFiscalCode="false"
        :isProfileForm="true"
        :showButtons="false"
        :showFiscalCode="false"
        :isFieldDisabled="false"
        @validate="validate"
      />
      <!-- <UserAddress
      ref="addressDataForm"
      :userData.sync="userData"
      :supportData.sync="supportData"
      :isFieldDisabled="true"
      :isProfileForm="true"
      :showButtons="false"
    /> -->
      <div class="py-3">* {{ $t("profile.requiredFields") }}</div>
      <v-divider class="my-8"></v-divider>
    </div>

    <!-- PRIVACY -->
    <v-form ref="privacyForm" v-model="isValid">
      <v-row no-gutters>
        <v-col cols="12">
          <div
            class="privacy-conditions-wrapper general-conditions disclaimer-text grey lighten-3 my-4 pa-2 rounded-lg"
          >
            <iframe
              class="w-100"
              height="300"
              src="/general-conditions.htm"
              frameborder="0"
              scrolling="yes"
            ></iframe>
          </div>
        </v-col>
        <v-col cols="12">
          <v-checkbox
            :rules="checkboxRules"
            v-model="consents.general"
            class="disclaimer-checkbox"
            color="primary"
            false-value="false"
            true-value="true"
            required
            @change="updateConsents"
          >
            <template v-slot:label>
              <span
                class="disclaimer-label disclaimer-checkbox-label"
                v-html="$t('register.privacy.requiredCheckGeneral')"
              ></span>
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12">
          <div
            class="privacy-conditions-wrapper general-conditions disclaimer-text grey lighten-3 my-4 pa-2 rounded-lg"
          >
            <iframe
              class="w-100"
              height="300"
              src="/privacy-terms.htm"
              frameborder="0"
              scrolling="yes"
            ></iframe>
          </div>
        </v-col>

        <v-col cols="12">
          <v-checkbox
            :rules="checkboxRules"
            v-model="consents.privacy"
            class="disclaimer-checkbox"
            color="primary"
            false-value="false"
            true-value="true"
            required
            @change="updateConsents"
          >
            <template v-slot:label>
              <span
                class="disclaimer-label disclaimer-checkbox-label"
                v-html="$t('register.privacy.requiredCheckPrivacy')"
              ></span>
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12">
          <v-checkbox
            v-model="consents.marketing"
            class="disclaimer-checkbox"
            color="primary"
            false-value="false"
            true-value="true"
            @change="updateConsents"
          >
            <template v-slot:label>
              <span
                class="disclaimer-label disclaimer-checkbox-label"
                v-html="$t('register.privacy.consents.marketing')"
              ></span>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="consents.profilation"
            class="disclaimer-checkbox"
            color="primary"
            false-value="false"
            true-value="true"
            @change="updateConsents"
          >
            <template v-slot:label>
              <span
                class="disclaimer-label disclaimer-checkbox-label"
                v-html="$t('register.privacy.consents.profilation')"
              ></span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-form>
    <v-col class="pa-0 ml-auto" cols="12">
      <div class="d-flex justify-end py-1">
        <v-btn
          color="primary"
          large
          depressed
          v-html="$t('profile.updatePersonalData')"
          class="rounded-pill px-6"
          @click="validate"
        />
      </div>
    </v-col>
    <div v-show="!selfScanning">
      <!-- UPDATE PERSONAL DATA -->

      <!-- UPDATE EMAIL -->
      <p
        class="text-uppercase text-h4 font-weight-bold px-0 mt-10"
        v-html="`${$t('profile.edit.email')}`"
      />
      <v-form ref="emailform" v-model="isEmailFormValid">
        <v-row class="my-3">
          <v-col cols="12" sm="6">
            <!-- new email -->
            <label v-html="`${$t('profile.email.newEmail')}*`" />
            <v-text-field
              v-model="newEmail"
              :placeholder="`${$t('profile.email.newEmail')}*`"
              :rules="emailRules"
              required
              outlined
              dense
              hide-details="auto"
              class="mt-1 rounded-pill"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <!-- confirm email -->
            <label v-html="`${$t('profile.email.emailConfirm')}*`" />
            <v-text-field
              v-model="emailConfirm"
              :placeholder="`${$t('profile.email.emailConfirm')}`"
              :rules="emailConfirmRules"
              outlined
              dense
              hide-details="auto"
              @paste.prevent
              class="mt-1 rounded-pill"
            />
          </v-col>
        </v-row>
        <span>* {{ $t("profile.requiredFields") }}</span>
        <v-col class="pa-0 ml-auto" cols="12">
          <div class="d-flex justify-end py-1">
            <v-btn
              color="primary"
              large
              depressed
              :disabled="!isEmailFormValid"
              @click="updateEmail()"
              :loading="loadingEmailChange"
              class="rounded-pill px-6"
            >
              {{ $t("profile.updateEmailData") }}
            </v-btn>
          </div>
        </v-col>
      </v-form>

      <!-- UPDATE PASSWORD -->
      <p
        class="text-uppercase text-h4 font-weight-bold px-0 mt-10"
        v-html="`${$t('profile.edit.password')}`"
      />
      <v-form ref="passwordform" v-model="isPasswordFormValid">
        <v-row class="my-3">
          <!-- password -->
          <!-- <v-col cols="12" sm="5">
          <label v-html="`${$t('profile.oldPassword')}*`" />
          <v-text-field
            v-model="oldPassword"
            :placeholder="`${$t('profile.oldPassword')}`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          />
        </v-col> -->
          <v-col cols="12" sm="6">
            <!-- password -->
            <label v-html="`${$t('profile.password')}*`" />
            <v-text-field
              v-model="password"
              :placeholder="`${$t('profile.password')}*`"
              :append-icon="showPassword ? '$eye' : '$eyeOff'"
              :type="showPassword ? 'text' : 'password'"
              :rules="passwordRules"
              @click:append="toggleShowPassword"
              required
              outlined
              dense
              hide-details="auto"
              class="mt-1 rounded-pill"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <!-- conferma password -->
            <label v-html="`${$t('profile.passwordConfirm')}*`" />
            <v-text-field
              :placeholder="`${$t('profile.passwordConfirm')}*`"
              :append-icon="showPassword ? '$eye' : '$eyeOff'"
              :type="showPassword ? 'text' : 'password'"
              :rules="passwordConfirmRules"
              @click:append="toggleShowPassword"
              required
              outlined
              dense
              @paste.prevent
              hide-details="auto"
              class="mt-1 rounded-pill"
            />
          </v-col>
        </v-row>
        <span>* {{ $t("profile.requiredFields") }}</span>
        <v-col class="pa-0 ml-auto" cols="12" md="5">
          <div class="d-flex justify-end py-1">
            <v-btn
              color="primary"
              large
              depressed
              :disabled="!isPasswordFormValid"
              @click="updatePassword()"
              :loading="loadingPasswordChange"
              class="rounded-pill px-6"
            >
              {{ $t("profile.updatePassword") }}
            </v-btn>
          </div>
        </v-col>
      </v-form>

      <!-- DELETE ACCOUNT -->
      <div class="anonymize-block mt-10 px-0">
        <p class="text-h3" v-html="$t('profile.header.anonymizeAccount')" />
        <span
          >Tramite questa funzione il tuo account verrà eliminato e non potrai
          più fare ordini<br />
          L'opzione è disponibile solo se non hai ordini da ritirare
        </span>
        <ResponseMessage class="mt-3" :response="responseAnonymazeAccount" />
        <div no-gutters class="d-flex justify-end mt-3 mt-sm-6">
          <v-btn
            large
            color="primary"
            depressed
            class="text-uppercase mb-2 rounded-pill"
            @click="anonymizeAccount()"
            :loading="loadingAnonymizeAccount"
            >{{ $t("profile.anonymizeAccountBtn") }}
          </v-btn>
        </div>
      </div>
    </div>
    <v-dialog v-model="invalidForm" max-width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Campi mancanti o invalidi
        </v-card-title>

        <v-card-text class="mt-6">
          Controlla il formulario, esistono campi invalidi o campi obbligatorii
          non compilati.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="invalidForm = false">
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeDialog" max-width="400">
      <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div>
      <v-card>
        <v-card-title class="anonymize-dialog-title text-h2">
          {{ $t("profile.anonymizeProfileDialog.title") }}
        </v-card-title>
        <v-card-text
          class="anonymize-dialog-text text-body-1"
          style="font-size: 14px !important"
          v-html="$t('profile.anonymizeProfileDialog.text')"
        >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            outlined
            @click.prevent="anonymizeAccount(true)"
            @mousedown.stop
          >
            {{ $t("common.yes") }}
          </v-btn>
          <v-btn class="primary" depressed text @click="removeDialog = false">
            {{ $t("common.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style lang="scss">
iframe {
  overflow-y: scroll;
}
.profile-update-container {
  :deep(.v-text-field--outlined) {
    fieldset {
      color: #dadbdc !important;
    }
  }
  label {
    font-weight: 600;
    text-transform: uppercase;
  }
  .v-autocomplete {
    box-shadow: none;
    -webkit-box-shadow: none;
    input {
      font-size: 16px !important;
    }
  }
  .disclaimer-checkbox {
    .v-label {
      font-size: 12px !important;
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import UserData from "@/components/profile/form/UserData.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
// import PrivacyOptions from "@/components/profile/form/PrivacyOptions.vue";

import categoryMixins from "~/mixins/category";
import { getPersonInfo, setPersonInfo } from "~/service/ebsn";

import UserService from "~/service/userService";
import StoreService from "~/service/storeService";
import CustomService from "@/service/customService";

import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { mapActions, mapState } from "vuex";
import {
  requiredValue,
  isEmail,
  isNumber,
  minLength,
  minCapitalCharacter,
  minSymbolCharacter,
  isCheckboxTrue
} from "~/validator/validationRules";

export default {
  name: "ProfileUpdate",
  components: {
    CategoryTitle,
    UserData,
    //UserAddress,
    ResponseMessage
    // PrivacyOptions
  },
  mixins: [categoryMixins],
  directives: { mask },
  data() {
    return {
      isValid: true,
      showPassword: false,
      oldPassword: null,
      loadingPasswordChange: false,
      password: null,
      disclaimerList: null,
      userDisclaimer: null,
      registrationModules: null,
      isDisclaimerFormValid: false,
      loadingDisclaimerChange: false,
      disclaimerToChange: null,
      invalidForm: false,
      disclaimerOptions: {
        color: "primary"
      },
      consents: {
        general: "false",
        privacy: "false",
        marketing: "false",
        profilation: "false"
      },
      requiredRules: requiredValue,
      requiredValue: requiredValue,
      checkboxRules: [
        requiredValue("È obbligatorio accettare questo consenso per procedere"),
        isCheckboxTrue("È obbligatorio accettare questo consenso per procedere")
      ],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      emailConfirmRules: [
        v => !!v || "Confermare l'e-mail",
        v => v === this.newEmail || "L'e-mail non coincidono"
      ],
      isCheckboxTrue: [requiredValue(), isCheckboxTrue()],
      dateRules: [requiredValue("Digitare la data di nascita")],
      isEmail: isEmail,
      isNumber: isNumber,
      fidelityCardRules: [
        requiredValue(),
        isNumber("Deve contenere solo cifre")
      ],
      passwordRules: [
        requiredValue("Digitare la password"),
        minLength(8, "La password deve essere lunga almeno 8 caratteri"),
        minCapitalCharacter(),
        minSymbolCharacter()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ],
      userData: {
        person: {},
        contact: {}
      },
      supportData: {
        privacy1: true,
        privacy2: true,
        country: {
          itemId: null
        }
      },
      billingAddress: {},
      provinces: [],
      response: {},
      loadingAnonymizeAccount: false,
      removeDialog: false,
      responseAnonymazeAccount: {},
      isPasswordFormValid: false,
      isEmailFormValid: false,
      newEmail: null,
      emailConfirm: null,
      loadingEmailChange: false,
      selectedStore: null,
      storeList: []
    };
  },
  computed: {
    ...mapGetters({
      isBetaTester: "cart/isBetaTester"
    }),
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout"
    }),
    async handleSetPersonInfo(person, personInfoTypeId, value) {
      await setPersonInfo(person, personInfoTypeId, value);
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async getUserData() {
      const result = await UserService.getUserDetail();
      if (result) {
        this.userData = result;
      }
    },
    async validate() {
      let personalData = this.$refs.personalDataForm.$refs.form;
      let privacyFrom = this.$refs.privacyForm;
      console.log("bbb personalData", personalData, personalData.validate());
      console.log("bbb privacyFrom", privacyFrom, privacyFrom.validate());
      const isValid =
        privacyFrom.validate() &&
        (this.selfScanning || personalData.validate());

      if (isValid) {
        if (this.selfScanning) {
          const allowedPersonInfoTypes = ["107", "101", "102", "108"];

          for (const personInfo of this.userData.person.personInfos) {
            if (allowedPersonInfoTypes.includes(personInfo.personInfoTypeId)) {
              await UserService.updatePersonInfo(
                personInfo.personInfoTypeId,
                personInfo.value
              );
            }
          }
          this.$router.push("/welcome-selfscanning");
        } else {
          await this.updateProfile();
        }
      } else {
        this.invalidForm = true;
      }
      const wId = this.userData.defaultStoreAddressId?.warehouseId;
      if (wId) {
        this.userData.defaultStoreAddressId = wId;
      }
    },
    back() {
      this.$router.back();
    },
    setPersonInfo(personInfoTypeId, value) {
      const privacyPersonInfo = {
        personInfoTypeId: personInfoTypeId,
        value: this.consents.privacy
      };

      let existingPersonInfo = this.userData.person.personInfos.find(
        info => info.personInfoTypeId === personInfoTypeId
      );

      if (existingPersonInfo) {
        existingPersonInfo.value = value;
      } else {
        this.userData.person.personInfos.push(privacyPersonInfo);
      }
    },
    updateConsents() {
      this.setPersonInfo("107", this.consents.general);
      this.setPersonInfo("101", this.consents.privacy);
      this.setPersonInfo("102", this.consents.marketing);
      this.setPersonInfo("108", this.consents.profilation);
    },
    async updateProfile() {
      const allowedPersonInfoTypes = ["107", "101", "102", "108"];
      this.userData.person.personInfos = this.userData.person.personInfos.filter(
        info => allowedPersonInfoTypes.includes(info.personInfoTypeId)
      );
      UserService.updateUserDetail(this.userData).then(() => {
        // this.loadCart();
        // if (this.selfScanning) {
        //   this.$router.push("/welcome-selfscanning");
        // }
      });
    },
    async updatePassword() {
      let _this = this;
      try {
        _this.response = {};
        _this.loadingPasswordChange = true;
        if (_this.$refs.passwordform.validate()) {
          let res = await CustomService.updatePassword(_this.password);
          _this.loadingPasswordChange = false;
          if (res) {
            _this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingPasswordChange = false;
      }
    },
    async updateEmail() {
      try {
        this.response = {};
        this.loadingEmailChange = true;
        if (this.$refs.emailform.validate()) {
          this.userData.email = this.newEmail;
          let res = await CustomService.updateEmailRequest(this.userData);
          this.loadingEmailChange = false;
          if (res) {
            this.loadCart();
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loadingEmailChange = false;
      }
    },

    async anonymizeAccount(perfom = false) {
      this.removeDialog = true;
      if (perfom) {
        this.loadingAnonymizeAccount = true;
        let result = await UserService.anonymizeAccount();
        if (result.response.status === 0) {
          this.removeDialog = false;
          // se utente davvero anonimizzato -> logout
          await this.doLogout();
          this.loadingAnonymizeAccount = false;
          this.$router.push({ name: "Home" });
        } else {
          this.responseAnonymazeAccount = result.response;
          this.removeDialog = false;
          this.loadingAnonymizeAccount = false;
        }
      }
    },
    async fetchStoreList() {
      let data = await StoreService.getStores();
      return data.stores;
    }
  },
  async mounted() {
    await this.getUserData();
    this.consents.general = getPersonInfo(this.userData.person, "107");
    this.consents.privacy = getPersonInfo(this.userData.person, "101");
    this.consents.marketing = getPersonInfo(this.userData.person, "102");
    this.consents.profilation = getPersonInfo(this.userData.person, "108");
    //await this.loadDisclaimer();
    //await userService.getUserDisclaimerListByType();
    //await this.getUserDisclaimer();
  }
};
</script>
