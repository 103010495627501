<template>
  <!-- FIDELTY CARD NUMBERE -->
  <v-form class="d-flex flex-wrap" ref="form" @submit.prevent v-model="isValid">
    <v-row>
      <!-- NOME DEL UTENTE -->
      <v-col cols="12" md="6" v-if="askFirstName">
        <label v-html="`${$t('register.card.step-0.placeholder.name')} *`" />
        <v-text-field
          :placeholder="`${$t('register.card.step-0.placeholder.name')} *`"
          :rules="genericPeopleNameRule"
          class="mt-1 rounded-pill"
          required
          outlined
          dense
          type="text"
          v-model="userData.person.firstName"
          :disabled="isFieldDisabled || hasFidelityCard"
          hide-details="auto"
        />
      </v-col>
      <!-- COGNOME DEL UTENTE-->
      <v-col cols="12" md="6" v-if="askLastName">
        <label v-html="`${$t('register.card.step-0.placeholder.surname')} *`" />
        <v-text-field
          :placeholder="`${$t('register.card.step-0.placeholder.surname')} *`"
          :rules="genericPeopleNameRule"
          class="mt-1 rounded-pill"
          required
          outlined
          dense
          type="text"
          v-model="userData.person.lastName"
          :disabled="isFieldDisabled || hasFidelityCard"
          hide-details="auto"
        />
      </v-col>
      <!-- DATE DI NASCITA DEL UTENTE-->
      <v-col cols="12" md="6">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <label v-html="`${$t('profile.birthDate')} *`" />
            <v-text-field
              v-model="birthDate"
              :placeholder="`${$t('profile.birthDate')} *`"
              :rules="dateRules"
              class="mt-1 rounded-pill"
              required
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              :disabled="isFieldDisabled || hasFidelityCard"
              hide-details="auto"
              append-icon="$calendar"
              @click:append="menu = true"
              readonly
            >
            </v-text-field>
          </template>
          <v-date-picker
            locale="it-IT"
            ref="picker"
            color="primary"
            :active-picker.sync="activePicker"
            v-model="userData.person.birthDate"
            min="1910-01-01"
            :max="$dayjs().format('YYYY-MM-DD')"
            first-day-of-week="1"
            type="date"
            no-title
            @input="onBirthdateInput"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <!-- Gender -->
      <v-col cols="12" md="6">
        <label v-html="`${$t('profile.gender')}`" />
        <v-radio-group v-model="userData.person.gender" row hide-details="auto">
          <v-radio label="F" value="F" color="default"></v-radio>
          <v-radio label="M" value="M" color="default"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" md="6">
        <!-- Fiscal Code -->
        <label v-html="`${$t('profile.fiscalCode')}` + '*'" />
        <v-text-field
          v-model="userData.person.fiscalCode"
          :rules="fiscalCodeRules"
          :placeholder="`${$t('profile.fiscalCode')}`"
          hide-details="auto"
          outlined
          dense
          disabled
          class="mt-1 rounded-pill"
        />
      </v-col>

      <v-col cols="12" md="6">
        <label v-html="`${$t('profile.contacts.homePhone')} *`" />
        <v-text-field
          :placeholder="`${$t('profile.contacts.homePhone')} *`"
          :rules="phoneRules"
          class="mt-1 rounded-pill"
          required
          outlined
          dense
          type="text"
          v-model="userData.contact.homePhone"
          :disabled="isFieldDisabled"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="12" md="6">
        <label v-html="`${$t('profile.contacts.mobilePhone')} *`" />
        <v-text-field
          :placeholder="`${$t('profile.contacts.mobilePhone')} *`"
          :rules="mobilePhoneRules"
          class="mt-1 rounded-pill"
          required
          outlined
          dense
          type="text"
          v-model="userData.phone"
          :disabled="isFieldDisabled"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="12" md="6">
        <label v-html="`${$t('profile.contacts.email')}`" />
        <v-text-field
          :placeholder="`${$t('profile.contacts.email')} *`"
          class="mt-1 rounded-pill"
          required
          outlined
          dense
          type="text"
          v-model="userData.email"
          disabled
          hide-details="auto"
        />
      </v-col>
      <div
        class="w-100 d-flex flex-column flex-md-row justify-sm-space-between justify-md-start"
        v-if="showButtons"
      >
        <!--STEP BACK-->
        <v-col md="5">
          <v-btn
            depressed
            block
            outlined
            x-large
            v-html="$t('register.card.step-0.btnBack')"
            @click="back"
            class="mb-5 mb-sm-0"
          />
        </v-col>
        <!--STEP FORWARD-->
        <v-col md="5">
          <v-btn
            depressed
            color="primary"
            block
            x-large
            :disabled="disabledRegistration"
            v-html="$t('register.card.step-0.btnConfirm')"
            @click="submit"
        /></v-col>
      </div>
    </v-row>
  </v-form>
</template>
<style scoped lang="scss">
:deep(.v-text-field--outlined) {
  fieldset {
    color: #dadbdc !important;
  }
}
</style>
<script>
import {
  requiredValue,
  isAlpha,
  isTelephone,
  isMobilePhone,
  genericPeopleName
} from "~/validator/validationRules";

import { isFiscalCode } from "@/customValidationRules";

export default {
  name: "UserDataForm",
  props: {
    userData: {
      type: Object,
      required: true
    },
    supportData: {
      type: Object,
      required: true
    },
    isFieldDisabled: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: true },
    showFiscalCode: { type: Boolean, default: false },
    isProfileForm: { type: Boolean, default: false },
    registrationType: { type: String, default: "OldUser" },
    isRegisterWithCard: { type: Boolean, default: false },
    isFiscalCode: { type: Boolean, default: true },
    askFirstName: { type: Boolean, default: true },
    askLastName: { type: Boolean, default: true }
  },
  data() {
    return {
      isValid: false,
      menu: false,
      activePicker: null,
      requiredRules: requiredValue,
      isAlpha: isAlpha,
      genericPeopleNameRule: [requiredValue(), genericPeopleName()],
      dateRules: [requiredValue("Digitare la data di nascita")],
      phoneRules: [requiredValue(), isTelephone()],
      mobilePhoneRules: [requiredValue(), isMobilePhone()],
      fiscalCodeRules: [requiredValue(), isFiscalCode()],
      response: {}
    };
  },
  computed: {
    disabledRegistration() {
      return !this.isValid;
    },
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("DD/MM/YYYY");
      }
    },
    hasFidelityCard() {
      return !!this.userData.fidelityCard;
    }
  },
  methods: {
    onBirthdateInput() {
      this.menu = false;
    },
    back() {
      this.$emit("back");
    },
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          if (_this.isProfileForm) {
            _this.$emit("validate");
          }
        }
      }, 200);
    },
    submit() {
      if (this.isValid) {
        if (
          this.registrationType == "NewUser" &&
          this.showFiscalCode &&
          !this.isRegisterWithCard
        ) {
          this.userData.person.gender = null;
        }
        this.$emit("submit", this.userData, this.supportData, this.birthDate);
      }
    }
  },
  created() {
    //  this.loadCountriesList();
    //this.showFiscalCode = this.askFiscalCode;
    /*if (
      this.$route.path === "/registration-card" &&
      !this.userData.fidelityCard
    ) {
      this.back();
    } else {
      this.loadCountriesList();
      this.showFiscalCode = this.askFiscalCode;
    }*/
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  }
};
</script>
